import React from 'react';
import Img from 'gatsby-image';

const GatsbyImage = ({ image, title, minHeight, className, fixedImage }) => {
  return (
    <Img
      className={`gatsby-image ${className}`}
      fluid={image}
      alt={title}
      style={{ minHeight: `${minHeight}px` }}
      fixed={fixedImage}
    />
  );
};

export default GatsbyImage;
